@use "colors";
@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@300..700&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

@layer tailwind-base, primeng, tailwind-utilities, base;

@layer tailwind-base {
  @tailwind base;
}

@layer tailwind-utilities {
  @tailwind components;
  @tailwind utilities;
}

@layer base {
  @include colors.colors;
}

body {
  padding: 0;
  margin: 0;
  color: var(--text-color);
}
